.sellingTextContent{
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
#specialistList .sellingTextContent{
    justify-content: initial;
}
.randomText{
    padding-top: 50px;
    font-size: 24px;
    font-weight: 600;
    width: 683px;
    padding-bottom: 73px;
}
.repListTitle{
    display: flex;
    flex-direction: row;
    padding-bottom: 34px;
}
.repListTitle h1{
    position: relative;
    font-size: 36px;
    font-weight: 600;
    padding-right: 80px;
    line-height: 1;
}
.repListTitle h2{
    line-height: 1.75;
}
.representativesMain .Nav{
    background-color: #F5f5f5;
    top: 0px;
    left: 0px;
    padding-bottom: 73px;
    padding-top: 30px;
}
.representativesMain{
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    background-color: #F5f5f5;
    position: relative;
}
.repList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.repName{
    font-size: 24px;
    font-weight: 600;
    width: 85%;
}
.repContacts{
    font-size: 18px;
    font-weight: 500;
    color: grey;
}
.rep{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 180px;
    width: 24%;
    /* border-bottom:1px solid #a7a7a7; */
    padding-bottom:40px;
    padding-top:40px;
    
}
.repListColumns{
    display: flex;
}
.repListColumns .rep{
    border-bottom: none !important;
    min-width: 310px;
}
.repListColumns .repList{
    border-right: solid 1px black;
    padding-left: 50px;
    flex-direction: column;
    width: 310px;
    min-width: 310px;
}
.repListColumns .repList:first-child{
    padding-left: 0;
}
.repListColumns .repList:last-child{
    border-right: none;
}
#specialistList .rep {
    justify-content: space-between;
    height: 140px;
}
.repLocationOrange{
    font-size: 18px;
    font-weight: 600;
    color:#e15303;
}
.smallRepHeader{
    display: none;
}
.rep a{
    color:#e15303;
}

.reps-dropdown-div{
    align-self: flex-end;
    height: 20px;
    z-index: 2;
    position: relative;
    width: 300px;
    left: 0px;
    top: -4px;
}

.reps-dropdown-div button{
    font-size: 18px;
    font-weight: 600;
}
.information1 > hr, .informationBody > hr{
    border-bottom: 1px solid black;
}
@media only screen and (max-width: 1900px){
    .representativesMain{
        padding-left: 100px;
        padding-right: 10%;
    }
}
@media only screen and (max-width: 1550px){
    .repListTitle{
        flex-direction: column;
        padding-bottom: 0px;
    }
    .repListTitle hr{
        width: 80%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .repListTitle h1{

        line-height: 30px;
        padding-bottom: 20px;
    }

    .representativesMain{
        padding-top: 30px;
        padding-left: 100px;
        padding-right: 100px;
        padding-bottom: 50px;
        margin-bottom: 50px;
    }
    .reps-dropdown-div{
        align-self: flex-start;
    }
}

@media only screen and (max-width: 1300px){
    .bigRepHeader{
        display: none;
    }
    .smallRepHeader{
        display: flex;
    }
    .randomText{
        display: none;
    }
    .repListTitle{
        flex-direction: column;
        padding-bottom: 0px;
    }
    .repListTitle hr{
        width: 80%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .repListTitle h1{
        font-size: 24px;
        line-height: 30px;
        padding-bottom: 20px;
    }
    .repListTitle h2{
        font-size: 16px;
    }
    .repList{
        flex-direction: column;
        width: 80%;
    }
    .rep{
        width: 100%;
        border-bottom: 1px solid #a7a7a7 !important;
    }
    .representativesMain{
        padding-left: 80px;
        padding-right: 00px;
        padding-bottom: 50px;
        margin-bottom: 0px;
    }
    .reps-dropdown-div{
        align-self: flex-start;
    }
    #mobileBlack{
        color:#e15303;
        font-size: 14px;
    }
}
@media only screen and (max-width: 600px){
    .representativesMain{
        padding-left: 50px;
        padding-right: 50px;
    }
}
@media only screen and (max-width: 500px) {
    .repList{
      width: 100%;
    }
}
@media only screen and (max-width: 1600px) {
    .repListColumns{
      display: none;
    }
}
@media only screen and (max-width: 340px) {
    .rep{
      height: 240px;
    }
}