.App{
  padding: 0px;
}
.katilai{
  padding-left: 100px;
  padding-right: 100px;
}
.infoFooter{
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 80px;
}
.locationFooter{
  background-color: #f5f5f5;
  padding-left: 100px;
  height: 700px;
}
.smallHeader{
  display: none;
}
.before-katilai .Nav{
  position: static;
  padding-bottom: 40px;
  padding-top: 70px;
}
@media only screen and (min-width: 1921px) {
  .App{
    display: grid;
  }
  footer{
    display: grid;
  }
  .katilai{
    padding: 0;
    justify-self: center;
  }
  .infoFooter{
    padding: 0;
    padding-bottom: 80px;
    justify-self: center;
    
  }
  .locationFooter{
    padding: 0;
    display: grid;
    
  }
  #bottomFooter{
    width: 1668px;
    justify-self: center;
  }
}

@media only screen and (max-width: 1900px) {
  .katilai{
    padding-left: 100px;
    padding-right: 10%;
  }
  .infoFooter{
    padding-left: 100px;
    padding-right: 10%;
    padding-bottom: 80px;
  }
  .locationFooter{
    background-color: #f5f5f5;
    padding-left: 100px;
    height: 700px;
  }
}
@media only screen and (max-width: 1300px) {
  .locationFooter{
    height: 500px;
  }
  .mainPageHeader{
    display: none;
  }

  td .BigData{
    display: none;
  }
  .smallHeader{
    display:block;
  }
  .katilai{
    padding-left: 0;
    padding-right: 0;
  }
  .infoFooter{
    padding-top: 70px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
  }
  .locationFooter{
    padding-left: 80px;
    padding-right: 80px;
    height: 600px;
  }
}
@media only screen and (max-width: 600px) {
  .infoFooter{
    padding-left: 50px;
    padding-right: 50px;
  }
  .locationFooter{
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (max-width: 426px) {
  /* .infoFooter{
    padding-left: 10px;
    padding-right: 10px;
  } */
}