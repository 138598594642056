.mainAbout{
    display: flex;
    flex-direction: column;
}
.about1, .about2{
    display: flex;
    flex-direction: row;
    height: 750px;
    background-color: #f5f5f5;
}
.aboutImages1, .aboutImages2{
    display: flex;
    flex-direction: row;
    height: 750px;
    width: 50%;
}
.biggerRandomAboutImage{
    position: absolute;
    right:0;
    height: 750px;
    width: 50%;
}
.smallerRandomAboutImage{
    position: absolute;
    right: 0;
    width: 20%;
    height: 750px;
}
.aboutText1{
    width: 35%;
    padding-left: 100px;
    height: 750px;
}
.aboutText1 .Nav{
    left: 0;
    top: 0;
    padding-top: 30px;
}
.excludeNav h1{
    padding-top: 50px;
    line-height: 1.3;
}
.biggerAboutText{
    padding-top: 50px;
    padding-left: 57px;
    padding-right: 60px;
    font-size: 18px;
    font-weight: 600;
}
.aboutStatistic{
    display: flex;
    padding-top: 57px;
    padding-left: 57px;
    font-size: 14px;
    font-weight: 600;
    
}
.statisticImage{
    padding-top: 7px;
    padding-right: 20px;
    width: 19px;
    height: 32px;
}
.smallerAboutText{
    padding-top: 30px;
    padding-left: 57px;
    padding-right: 60px;
    font-size: 14px;
    font-weight: 500;
}
.schematicsImage{
    position: absolute;
    left:0;
    height: 750px;
    width: 50%;
}
.about2{
    background-color: #e6e6e6;
}
.aboutText2{
    width: 35%;
    padding-right: 12.3%;
    padding-left: 162px;
    padding-top: 200px;
    height: 750px;
}
.smallerAboutText2{
    padding-top: 30px;
    font-size: 14px;
    font-weight: 500;
}
.weAre{
    display: flex;
    flex-direction: row;
    padding-left: 100px;
}
.aboutPeopleList{
    display: flex;
    flex-direction: row;
}
.aboutPeopleList hr{
    display: block;
    margin-right: 40px;
}
.aboutPerson{
    display: flex;
    align-items: space-between;
    flex-direction: column;
    padding-right: 40px;
    height: 310px;
}
.aboutPersonName{
    padding-top: 40px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
}
.aboutPersonRole{
    font-size: 14px;
    font-weight: 600;
    color:grey;
    padding-bottom: 20px;
}
.aboutPeronContacts{
    
    font-size: 14px;
    font-weight: 500;
}
.greyLogo{
    height: 550px;
    right: 0;
    top: 0;
}
.excludeGreyLogo{
    padding-top: 95px;
}
.excludeGreyLogo h1{
    padding-bottom: 95px;
}
.aboutPersonImage{
    height: 100px;
    width: min-content;
}
.smallAboutHeader{
    display: none;
}
.aboutText2 h1{
    line-height: 1.3;
}
@media only screen and (max-width: 1900px){
    .aboutText1{
        padding-left: 100px;
    }
    .smallerAboutText{
        display: none;
    }
    .weAre{
        padding-left: 100px;
    }
}
@media only screen and (max-width: 1300px){
    .smallAboutHeader{
        display: flex;
    }
    #bigAboutHeader{
        display: none;
    }
    .aboutImages1{
        display: none;
    }
    .excludeNav h1{
        font-size: 24px;
    }
    .aboutText1{
        width: 80%;
        padding-left: 80px;
        height: auto;
    }
    .aboutStatistic{
        padding-top: 0;
        padding-left: 0px;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
    }
    .about2{
        display: none;
    }
    .smallerAboutText{
        display: none;
    }
    .biggerAboutText{
        padding-top: 50px;
        padding-left: 0;
        padding-right: 0;
        font-size: 18px;
        font-weight: 600;
        padding-bottom: 20px;
    }
    .excludeNav hr{
        width: 100%;
    }
    .statisticImage{
        padding-top: 7px;
        padding-right: 10px;
        width: 19px;
        height: 32px;
    }
    .about1{
        height: auto;
        padding-bottom: 56px;
    }
    .greyLogo{
        display: none;
    }
    .aboutPeopleList{
        flex-direction: column;
        padding-bottom: 20px;
    }
    .aboutPerson{
        flex-direction: row;
        padding-right: 40px;
        height: 130px;
        padding-top: 20px;
    }
    .aboutPersonText{
        padding-left: 20px;
        padding-bottom: 22px;
    }
    .aboutPersonName{
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 18px;
        font-weight: 600;
    }
    .aboutPersonRole{
        font-size: 14px;
        font-weight: 600;
        color:grey;
        padding-bottom: 10px;
    }
    .aboutPeronContacts{
        
        font-size: 14px;
        font-weight: 500;
    }
    .greyLogo{
        height: 550px;
        right: 0;
        top: 0;
    }
    .excludeGreyLogo{
        padding-top: 95px;
    }
    .excludeGreyLogo h1{
        padding-bottom: 95px;
    }
    .aboutPersonImage{
        width: 100px;
        height: 100px;
    }
    .weAre{
        display: flex;
        flex-direction: row;
        padding-left: 80px;
        background-color: #f5f5f5;
    }
    .excludeGreyLogo{
        padding-top: 0px;
    }
    .excludeGreyLogo h1{
        font-size: 24px;
        padding-bottom: 31px;
    }
    .aboutPeopleList hr{
        width: 81.4%;
    }
}
@media only screen and (max-width: 600px){
    .aboutText1{
        padding-left: 50px;
    }
    .weAre{
        padding-left: 50px;
    }
}
@media only screen and (max-width: 430px){
    .aboutPersonImage{
        display: none;
    }
    .aboutPersonText{
        padding-left: 0;
    }
}