@import url('https://rsms.me/inter/inter.css');

/**
 * Main wrapper
 */
.select-search-container {
    --select-search-background: #f5f5f5;
    --select-search-border: black;
    --select-search-selected: black;
    --select-search-text: #000;
    --select-search-subtle-text: #6c6f85;
    --select-search-inverted-text: var(--select-search-background);
    --select-search-highlight: #eff1f5;
    --select-search-font: 'Poppins', sans-serif;

    width: 600px;
    position: relative;
    font-family: var(--select-search-font);
    color: var(--select-search-text);
    box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
    .select-search-container {
        --select-search-font: 'Poppins', sans-serif;
    }
}



body.is-dark-mode .select-search-container {
    --select-search-background: #000;
    --select-search-border: #313244;
    --select-search-selected: #89b4fa;
    --select-search-text: #fff;
    --select-search-subtle-text: #a6adc8;
    --select-search-highlight: #1e1e2e;
}

body.is-light-mode .select-search-container {
    --select-search-background: #fff;
    --select-search-border: #000000;
    --select-search-selected: black;
    --select-search-text: #000;
    --select-search-subtle-text: #6c6f85;
    --select-search-highlight: #eff1f5;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
    box-sizing: inherit;
}

.select-search-input::placeholder{
    opacity: 1;
    color: #000;
}

.select-search-input {
    position: relative;
    z-index: 1;
    display: block;
    height: 55px;
    width: 100%;
    padding: 0 40px 0 50px;
    background: var(--select-search-background);
    border: 1px solid var(--select-search-border);
    color: var(--select-search-text);
    outline: none;
    font-family: var(--select-search-font);
    font-size: 18px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 48px;
    letter-spacing: 0.01rem;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    font-weight: 600;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.select-search-is-multiple .select-search-input {
    margin-bottom: -2px;
}

.select-search-is-multiple .select-search-input {
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search-input[readonly] {
    cursor: pointer;
}

.select-search-is-disabled .select-search-input {
    cursor: not-allowed;
}

.select-search-container:not(.select-search-is-disabled).select-search-has-focus .select-search-input,
.select-search-container:not(.select-search-is-disabled) .select-search-input:hover {
    border-color: var(--select-search-selected);
}

.select-search-select {
    background: var(--select-search-background);
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border: 1px solid var(--select-search-border);
    overflow: auto;
    max-height: 360px;
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
    position: absolute;
    z-index: 2;
    
    right: 0;
    left: 0;
    display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus .select-search-select {
    display: block;
}

.select-search-has-focus .select-search-select {
    border-color: var(--select-search-selected);
}

.select-search-options {
    list-style: none;
}

.select-search-option,
.select-search-not-found {
    display: block;
    height: 55px;
    width: 100%;
    padding: 0 50px;
    background: var(--select-search-background);
    border: none;
    outline: none;
    font-family: var(--select-search-font);
    color: var(--select-search-text);
    font-size: 18px;
    text-align: left;
    letter-spacing: 0.01rem;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    font-weight: 600;
}

.select-search-option:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: transparent !important;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
    background: var(--select-search-highlight);
}

.select-search-is-selected {
    font-weight: bold;
    /* color: var(--select-search-selected); */
    color: #e15303;
}

.select-search-group-header {
    font-size: 12px;
    text-transform: uppercase;
    background: var(--select-search-border);
    color: var(--select-search-subtle-text);
    letter-spacing: 0.1rem;
    padding: 10px 16px;
}

.region-search-div {
    padding-top: 70px;
}

.select-search-option:hover{
    color:#e15303 !important;
    font-weight: bold;
}
.select-search-row:not(:first-child){
    border-top: 1px solid rgb(190, 190, 190);
}
.select-search-row:not(:first-child) .select-search-group-header {
    
    margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
    margin-bottom: 10px;
}

@media only screen and (max-width: 1550px) {
    .region-search-div {
        padding-top: 40px;
    }
}
@media only screen and (max-width: 900px) {
    .select-search-input{
        padding-left: 27px;
    }
}
@media only screen and (max-width: 700px) {
    .select-search-container {
        width: 80%;
    }
}
@media only screen and (max-width: 400px) {
    .select-search-container {
        width: 100%;
    }
}