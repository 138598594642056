* {
    margin:0;
    padding:0;
    font-family: 'Poppins';
  }
  
  .row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .column {
    overflow-wrap: anywhere;
    
    width: 551px;
    display: flex;
    padding-right: 5px;
  }
  
  .columnContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 400px;
    width: 100%;
  }
  
  .twoLinks{
    display: flex;
    flex-direction: column;
  }
  
  a:link {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
  }

  .OrangeLink {
    color: #e15303;
    margin-top: 10px;
  }

  .OrangeLink:visited {
    color: #e15303;
  }

  .WhiteLink {
    color: white;
    margin-top: 10px;
  }

  .WhiteLink:visited {
    color: white;
  }

  .middleText{
    color: #666666;
    width: 80%;
    padding-top: 45px;
  }
  .phoneNumber{
    font-size: 36px;
    font-weight: 300;
    line-height: 35px;
    margin-top: 25px;
  }
  .email{
    font-size: 16px;
    font-weight: 600;
  }
  .katalogasButton{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: row;
    color: black;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    text-decoration: none;
    border-left: solid 1px black;
    width: auto;
    font-size: 16px;
    font-weight: 500;
    padding-left: 30px;
    padding-right: 30px;
  }
  .katalogasButton:first-child{
    padding-left: 0;
  }
  .katalogasButton.footerKatalogas{
    width: 306px;
    height: 55px;
    background-color: #d9d9d9;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .katalogasButton.footerKatalogas .fileIcon{
    filter: none;
    width: 15px;
    height: 20px;
    margin-left: 8px;
  }
  /* .kataloguButtons a{
    display: inline-block;
    height: 61px;
    border-right: solid 1px black;
  } */
  .kataloguButtons{
    display: flex;
    flex-direction: row;
  }
  .katalogasButton:first-child{
    border: none;
  }
  .columnContent .katalogasButton{
    padding-left: 0;
    padding-right: 0;
  }
  .title{
    font-size:36px;
    line-height: 40px;
    font-weight:600;
    min-height: 40px;
  }
  .usefulInfo{
    color: black;
    font-size: 12px;
    padding-top: 70px;
    padding-bottom: 50px;
    font-weight: 600;
  }

  hr{
    display: none;
  }
  
  .locationImageMap{
    vertical-align: top;
    width: 63vw;
    height: 700px;
  }

  .locationImage{
    vertical-align: top;
    width: 1598px;
    height: 700px;
  }

  .contactInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #f5f5f5;
    height: 700px;
    max-height: 500px;
  }
  .greyGradient{
    position: absolute;
    width: 60%;
    height: 700px;
    background: linear-gradient(90deg, #f5f5f5, rgba(255,0,0,0) 35%);
    z-index: 1000;
    pointer-events: none;
  }
  .akatilai_logo{
    padding-top: 80px;
    padding-bottom: 130px;
    /* width: 336px; */
    height: 70px;
  }
  .contactText{
    padding-top: 32px;
    color: #666666;
  }
  .copyright{
    font-size: 12px;
    padding-top: 50px;
    padding-bottom: 10px;
    color: #a7a7a7;
  }
  .nonTitleInfoContent{
    height: 234px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .contactInfo h1{
    font-size: 18px;
    font-weight: 600;
  }
  .contactText{
    font-size: 16px;
  }
  .fileIcon{
    position: relative;
    height: 30px;
    width: 22px;
    top:0px;
    padding-right: 10px;
    filter: invert(73%) sepia(30%) saturate(0%) hue-rotate(201deg) brightness(85%) contrast(99%);
  }
  .paleidimas.paleidimoFooterRow{
    width: 1668px;
    height: 510px;
    padding-top: 70px;
    padding-left: 0;
    padding-right: 0;
    justify-self: center;
  }
  .paleidimas.paleidimoFooterRow h1 {
    padding-bottom: 40px;
  }
  .paleidimas.paleidimoFooterRow p{
    width: 950px;
  }
  .paleidimas.paleidimoFooterRow .DetailsButton, .paleidimas .DetailsButton {
    background-color: white;
    color: #E15303;
    width: 476px;
    height: 55px;
    margin-top: 65px;
    box-shadow: 0px 0px 50px #FFFFFF80;
  }
  .paleidimas .DetailsButton {
    margin-top: 75px;
    width: 390px;
  }
  .paleidimas.paleidimoFooterRow .DetailsButton:hover, .paleidimas .DetailsButton:hover {
    box-shadow: none;
    transition: all 0.2s ease-in-out;
  }
  .row.paleidimoRow{
    display: grid;
    background-color: #E15303;
    margin-bottom: 20px;
  }
  .DetailsButton.paleidimoSpec{
    width: 476px;
    height: 55px;
  }
  @media only screen and (max-width: 1900px) {
    .greyGradient{
      width: 30%;
    }
    .paleidimas.paleidimoFooterRow{
      padding-left: 100px;
      padding-right: 10%;
      width: auto;
      justify-self: left;
    }
    .phoneNumber:first-child{
      margin-top: 0;
    }
    .locationImageMap{
      width: 60vw;
    }
    .column{
      width: 32.8%;
    }
    .columnContent{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 280px;
    }
    .title{
      font-size: 24px;
      line-height: 30px;
    }
  }
  @media only screen and (max-width: 1300px) {
    .usefulInfo{
      display: none;
    }
    .paleidimas.paleidimoFooterRow .DetailsButton, .paleidimas .DetailsButton {
      background-color: #E15303;
      color: white;
      margin-top: 40px;
      box-shadow: 0px 0px 16px 1px #e15303;
    }
    .paleidimoFooter {
      margin-bottom: 20px;
    }
    .row.paleidimoRow {
      background-color:#f5f5f5;
    }
    .paleidimas.paleidimoFooterRow{
      height: 440px;
      padding-top: 50px;
      padding-left: 80px;
      padding-right: 80px;
    }
    .paleidimas.paleidimoFooterRow p{
      width: auto;
    }
    .footerHR{
      display: block;
      width: 628px;
      margin-top: 30px;
      margin-bottom: 30px;
      border-bottom: none;
      border-top: 1px solid #707070;
    }
    .columnContent .katalogasButton{
      width: 350px;
    }
    .greyGradient{
      display: none;
    }
    .location{
      display: none;
    }
    #bottomFooter{
      padding-top: 0px;
    }
    .title{
      font-size: 24px;
      line-height: 30px;
    }
    .middleText{
      font-size: 12px;
    }
    .email{
      font-size: 14px;
    }
    .phoneNumber{
      font-size: 24px;
    }
    .columnContent{
      height: 240px;
    }
    .phoneNumber{
      margin-top: 10px;
    }
    .akatilai_logo{
      padding-bottom: 50px;
      width: 250px;
      height: 52.08px;
    }
    .copyright{
      padding-top: 50px;
    }
    .contactInfo h1{
      font-size: 14px;
    }
    .contactText{
      font-size: 14px;
    }
    .realZemelapis{
      display: none;
    }
    .middleText{
      display: none;
    }
    
    .nonTitleInfoContent{
      padding-top: 20px;
      height: auto;
    }
    .row{
      flex-direction: column;
    }
    .column {
      width: auto;
    }
    .footerHR{
      width: auto;
    }
}
@media only screen and (max-width: 900px) {
  #catalogueSection{
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .paleidimas.paleidimoFooterRow .DetailsButton, .DetailsButton.paleidimoSpec {
    background-color: #E15303;
    color: white;
    margin-top: 20px;
    margin-bottom: 0px;
    width: 400px;
  }
  .paleidimas.paleidimoFooterRow{
    padding-left: 50px;
    padding-right: 50px;
    height: 440px;
  }
  .paleidimas.paleidimoFooterRow p{
    width: 400px;
  }
}
@media only screen and (max-width: 500px) {
  .nonTitleInfoContent{
    padding-top: 0px;
  }
  .twoLinks > .OrangeLink{
    font-size: 14px;
  }

  .paleidimas.paleidimoFooterRow .DetailsButton, .paleidimas .DetailsButton {
    width: 80vw;
    min-width: 274px;
  }
  .nonTitleInfoContent.paleidimoSpec{
    width: 100%;
  }
  .DetailsButton.paleidimoSpec {
    width: 80vw;
    min-width: 274px;
  }
  .paleidimas.paleidimoFooterRow{
    width: 90%;
    min-width: 250px;
    height: 580px;
  }
  .paleidimas.paleidimoFooterRow p{
    width: 90%;
    min-width: 250px;
  }

}
@media only screen and (max-width: 390px) {
  .twoLinks > .OrangeLink{
    font-size: 12px;
    min-width: 300px;
  }
  .Price .OrangeLink{
    min-width: 300px;
    font-size: 14px;
  }
}
