.topNav{
    display: flex;
    flex-direction: row;
}
.hamburger{
    width: 50px;
    height: 50px;
    z-index: 1010;
}
.hamburger:hover #small_tree_links{
    display: block;
    opacity: 100%;
}
.hambutton{
    position: absolute;
    right: 0px;
    top: 30px;
    padding-top: 10px;
    z-index: 1005;
}
#small_tree_links{
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    height: 1080px;
    width: 100%;
    z-index: 1000;
    transition: opacity 0.25s;
    color: black;
}
#small_tree_links a{
    color: black;
    width: 75%;
}
.blackHeaderLogoImage{
    padding-left: 80px;
    padding-top: 42.5px;
    padding-bottom: 40.5px;
    /* width: 200px; */
    height: 41px;
}
.zodisMeniu{
    position: absolute;
    font-size: 16px;
    font-weight: 600;
    right: 35px;
    cursor: pointer;
}
.fadedClass{
    left:-3000px;
    opacity: 0;
}
.visibleClass{
    right:0;
    opacity: 100%;
}
.displayedClass{
    display: flex;
    flex-direction: column;
}
.displayedClass > a{
    font-size: 16px;
    margin-bottom: 20px;
    margin-left: 80px;
}
.displayedClass > div{
    margin-left: 80px;
}
.displayedClass > div > a{
    padding-right: 20px;
    padding-left: 20px;
    border-left: 1px solid #666666;
}
.displayedClass > div > a:first-child{
    border-left: none;
    padding-left: 0;
}
.displayedClass > a:hover{
    color: #e15303 !important;
}
.displayedClass > div > a:hover{
    color: #e15303 !important;
}
.displayedClass > a:first-child{
    margin-top: 100px;
}
.noneClass{
    display: none;
}

.container {
    padding-top: 5px;
    display: inline-block;
    cursor: pointer;
}
    
.bar1, .bar2, .bar3 {
    width: 20px;
    height: 3px;
    background-color: #333;
    margin: 2px 0;
    transition: 0.4s;
}
.closeMenuButton .CloseOrderButton{
    animation: xanim 0.25s ease-in forwards;
    left: 60%;
    justify-content: start;
}
@keyframes xanimsmall{
    100%{
        left: 50px;
    }
}
@keyframes xanim{
    100%{
        left: 80px;
    }
}
@media only screen and (max-width: 600px) {
    .blackHeaderLogoImage{
        padding-left: 50px;
    }
    .displayedClass > a{
        margin-left: 50px;
    }
    .displayedClass > div{
        margin-left: 50px;
    }
    .closeMenuButton .CloseOrderButton{
        animation: xanimsmall 0.25s ease-in forwards;
        left: 60%;
    }
}