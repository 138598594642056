.informationBody{
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
}
.informationTitle{
    padding-top: 50px;
    width: 797px;
    padding-left: 100px;
}
.informationBody .Nav{
    left: 0;
    top: 0;
    padding-top: 30px;
    padding-bottom: 73px;
    padding-left: 100px;
}
.paleidimas{
    width: 50%;
    padding-top: 81px;
    background-color: #E15303;
    padding-right: 12.3%;
    padding-left: 100px;
    color: white;
}
.pajungimas{
    width: 50%;
    padding-right: 12.3%;
    padding-top: 81px;
    padding-left: 100px;
}
.information1{
    display: flex;
    flex-direction: row;
    height: 681px;
}
.informationTitle h1{
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 65px;
}
.pajungimas h1, .paleidimas h1{
    padding-top: 20px;
    padding-bottom: 50px;
    font-size: 36px;
    font-weight: 600;
}
.paleidimas hr{
    border-top: 1px solid white;
    border-bottom: 0px solid white;
    display: block;
    width: 240px;
    margin-top: 100px;
    margin-bottom: 60px;
  }
  .pajungimas p, .paleidimas p{
    font-size: 14px;
    font-weight: 500;
  }
  .information2{
    display: flex;
    flex-direction: row;
    height: 700px;
  }
  .randomInfoImages{
    display: flex;
    height: 700px;
    width: 50%;
  }
  .biggerRandomInfoImage{
    position: absolute;
    width: 50%;
    left:0;
    height: 700px;
  }
  .smallerRandomInfoImage{
    position: absolute;
    width: 12.3%;
    left:0;
    height: 700px;
  }
.garantija{
    position: absolute;
    display: flex;
    flex-direction: row;
    right:0;
    height: 700px;
    width: 50%;
    background-color: white;
}
.greyInfoLogo{
    position: absolute;
    right: 0;
}
.greyInfoLogoImage{
    height: 700px;
    width: 700px;
    max-width: 100%;
}
.garantijaInfoLogo{
    display: flex;
    flex-direction: row;
}
.garantijaText{
    padding-left: 100px;
    padding-top: 100px;
    z-index: 100;
}
.garantijaText h1{
    font-size: 36px;
    font-weight: 600;
    width: 500px;
    padding-top: 20px;
    padding-bottom: 45px;
}
.garantijaText p{
    font-size: 14px;
    font-weight: 500;
    width: 86%;
}
.smallInfoHeader{
    display: none;
}
.paleidimasIconJuodas{
    display: none;
}

@media only screen and (max-width: 1900px){
    .informationTitle{
        padding-left: 100px;
    }
    .informationBody .Nav{
        padding-left: 100px;
    }
    .pajungimas{
        padding-left: 100px;
    }
}
@media only screen and (max-width: 1300px){
    .bigInfoHeader{
        display: none;
    }
    .smallInfoHeader{
        display: flex;
    }
    .informationTitle{
        padding-top: 30px;
        padding-left: 80px;
        padding-right: 19%;
        width: 71%;
        
    }
    .informationTitle h1{
        font-size: 18px;
        line-height: 30px;
        padding-bottom: 0;
    }
    .informationBody .Nav{
        left: 0;
        top: 0;
        padding-top: 15px;
        padding-bottom: 20px;
        padding-left: 100px;
    }
    .information1{
        flex-direction: column;
        height: auto;
    }
    .information2{
        flex-direction: column;
        height: auto;
    }
    .paleidimas{
        width: 80%;
        padding-top: 0px;
        padding-left: 80px;
        padding-right: 80px;
        background-color: #f5f5f5;
        color: black;
    }
    .pajungimas{
        width: 80%;
        padding-top: 50px;
        padding-left: 80px;
        padding-right: 80px;
    }
    .pajungimas p{
        font-size: 14px;
    }
    .pajungimas h1, .paleidimas h1{
        padding-top: 10px;
        padding-bottom: 20px;
        font-size: 20px;
        font-weight: 600;
    }
    .paleidimasIcon{
        display: none;
    }
    .paleidimasIconJuodas{
        display: flex;
    }
    .paleidimasIconJuodas img{
        width: 40px;
        height: 50px;
    }
    .pajungimasIcon img{
        width: 40px;
        height: 50px;
    }
    .paleidimas hr{
        border-top: 1px solid grey;
        border-bottom: 1px solid grey;
        display: none;
        width: 240px;
        margin-top: 100px;
        margin-bottom: 60px;
    }
    .information2 .randomInfoImages{
        display: none;
    }
    .garantija .greyInfoLogo{
        display: none;
    }
    .garantija{
        position: relative;
        height: auto;
        padding-left: 80px;
        padding-right: 80px;
        width: 80%;
        background-color: #f5f5f5;
        padding-bottom: 70px;
    }
    .informationBody{
        height: auto;
    }
    .garantijaText{
        padding-left: 0;
        padding-right: 0;
        padding-top: 0px;
    }
    .garantijaText h1{
        font-size: 20px;
        font-weight: 600;
        width: 80%;
        padding-top: 16px;
        padding-bottom: 12.5px;
    }
    .garantijaText p{
        font-size: 14px;
        font-weight: 500;
        width: 100%;
    }
    .information1 > hr, .informationBody > hr{
        display: block;
        margin-left: 100px;
        width: 80%;
        margin-top: 30px;
        margin-bottom: 30px;
        border-top: none;
    }
    .garantijaIcon img{
        width: 45px;
        height: 45px;
    }
    .paleidimas p{
        padding-bottom: 20px;
    }
}
@media only screen and (max-width: 600px){
    .information1 > hr, .informationBody > hr{
        margin-left: 50px;
    }
    .garantija{
        padding-left: 50px;
        padding-right: 50px;
    }
    .pajungimas{
        padding-left: 50px;
        padding-right: 50px;
    }
    .paleidimas{
        padding-left: 50px;
        padding-right: 50px;
    }
    .informationTitle{
        padding-left: 50px;
    }
}