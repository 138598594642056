  html {
    scroll-behavior: smooth;
  }
  .parent{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 741px;
    background-color: #f5f5f5;
    padding-left: 150px;
    padding-right: 150px;
  }
  .header_image{
    grid-column: 1 / 3;
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 1300px;
    
  }
  .headerLogoDiv{
    position: absolute;
    left: 100px;
    padding-top: 70px;
    top: 0;
  }
  .randomImage{
    width: 1600px;
    height: 731px;
    
  }
  .slider-wrapper {
    
    justify-self: center;
    grid-column: 3;
    position: relative;
    overflow: hidden;
    width: 550px;
    padding-right: 0px;
  }
  
  .slides-container {
    
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 741px;
  }
  
  .slide-arrow {
    position: absolute;
    
    top: 0;
    bottom: 0;
    margin: 0 auto;
    height: 55px;
    background-color: grey;
    border: none;
    width: 55px;
    font-size: 3rem;
    padding: 0;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 100ms;
  }
  
  .slide-arrow:hover {
    opacity: 1;
  }
  
  #slide-arrow-prev {
    left: 0;
    top: 686px;
  }
  
  #slide-arrow-next {
    left: 55px;
    top: 686px;
  }
  .slide .card{
    padding-left: 0px;
    padding-top: 0px;
  }
  .slide .cardDetailsButton{
    position: absolute;
    top: 686px;
    left: 110px;
  }

  .header_katilu_rusys a{
    padding-right: 30px;
    color: white;
  }
  .headerLogoImage{
    height: 91px;
    padding-bottom: 21px;
  }
  .tree_links{
    display: flex;
    position: absolute;
    /* left: 32vw; */
    top: 45px;
    right: 12%;
  }
  .tree_links a{
    color: white;
    font-size: 12px;
    
    white-space: nowrap;
  }
  .tree_links span{
    color: white;
    font-size: 12px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .tree_links a:hover{
    color: #e15303;
  }
  .tree_links > .language_header > a{
    padding-right: 15%;
  }
  .language_header{
    padding-top: 0%;
    padding-left: 30px;
    padding-right: 15px;
    position: relative;
    top: -4px;
  }
  .black_tree_links .language_header > a, .black_tree_links_only .language_header > a{
    padding-right: 40%;
  } 
  .header_image .sukis{
    color: white;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    top: 80%;
    left: 41vw;
    line-height: 30px;
    white-space: nowrap;
  }

  .randomImageDivOverlay{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 12.5vw;
    height: 100%;
    transition: .5s ease;
  }

  .header_image:hover .randomImageDivOverlay {
    width: 100%;
  }
  .blackHeaderLogoDiv .header_katilu_rusys a{
    color: black;
  }
  .header_katilu_rusys a:hover{
    color: #e15303;
  }
  .blackHeaderLogoDiv{
    padding-top: 60px;
    padding-left: 100px;
    
  }
  .black_tree_links span{
    padding-left: 30px;
    padding-right: 30px;
  }
  .black_tree_links_only{
    display: flex;
    position: sticky;
    top: 72px;
    right: 0;
    width: 100%;
    z-index: 3002;
    box-shadow: 0px 10px 10px -1px #8a8a8a33;
    height: 1px;
    background-color: white;
    padding-top: 7px;
  }
  .black_tree_links{
    display: flex;
    position: sticky;
    top: 109px;
    right: 0;
    width: 100%;
    z-index: 3002;
    box-shadow: 0px 10px 10px -1px #8a8a8a33;
    height: 1px;
    background-color: white;
    padding-top: 7px;
  }
  .goToRight{
    display: flex;
    position: absolute;
    right: 100px;
    top: -30px;
    z-index: 3003;
  } 
  #bigAboutHeader .black_tree_links, #bigAboutHeader .black_tree_links_only{
    top: 30px;
  }
   .black_tree_links a, .black_tree_links_only a{
    color: black;
    font-size: 12px;
    padding: 0;
    white-space: nowrap;
  }
  .black_tree_links a:hover, .black_tree_links_only a:hover{
    color: #e15303;
  }

  .headerActive {
    color: #e15303 !important;
  }
  .animationImages{
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
    list-style: none;
  }
  .animationImages img{
    height: 741px;
  }
  .slider-wrapper > .DetailsButton{
    position: relative;
    top: -55px;
    left: 110px;
  }
  .slider-wrapper .slide button{
    display: none;
  }

  @media only screen and (max-width: 1900px) {
    .headerLogoDiv{
      left: 100px;
    }
    .blackHeaderLogoDiv{
      padding-left: 100px;
    }
    .tree_links{
      /* left: 29vw; */
      right: 20%;
    }
  }
  @media only screen and (max-width: 1800px) {
    .tree_links{
      /* left: 24vw; */
      right: 27%;
    }
    .header_image .sukis{
      left: 37vw;
    }
  }
  @media only screen and (max-width: 1680px) {
    .tree_links{
      /* left: 20vw; */
      right: 30%;
    }
    .header_image .sukis{
      left: 34vw;
    }
  }
  @media only screen and (max-width: 1640px) {
    .animationImages img {
      height: auto;
    }
    .header_image .sukis{
      left: auto;
      right: 11.5%;
    }
    .slider-wrapper{
      display: none;
    }
    .parent{
      grid-template-columns: fit-content(300px) fit-content(300px) 1fr;
    }
    .randomImage{
      width: 100vw;
    }
    .tree_links{
      justify-content: flex-end;
      right: 100px;
    }
    .tree_links a {
      padding-right: 10px;
    }
    .language_header a:first-child{
      padding-right: 12px;
    }
    .header_image{
      width: 100%;
    }
  }
  @media only screen and (max-width: 1600px) {
    .tree_links{
      left: 48%;
    }
    .header_image .sukis{
      right: 16%;
    }
    .slider-wrapper{
      left: 510px;
    }
  }

  @media only screen and (max-width: 1440px) {
    .tree_links{
      left: 44%;
    }
    .header_image .sukis{
      right: 22%;
    }
    .slider-wrapper{
      left: 500px;
    }
    .headerLogoDiv{
      left: 100px;
    }
  }

  @media only screen and (max-width: 1366px) {
    .tree_links{
      left: 38%;
    }
    .header_image .sukis{
      right: 28%;
    }
    .slider-wrapper{
      left: 460px;
    }
    .headerLogoDiv{
      left: 100px;
    }
  }

  @media only screen and (max-width: 1300px) {
    .tree_links{
      left: 38%;
    }
    .header_image .sukis{
      right: 32%;
    }
    .slider-wrapper{
      left: 440px;
    }
    .headerLogoDiv{
      left: 100px;
    }
  }

  @media only screen and (min-width: 1921px) {
    .slider-wrapper {
      right: 0%;
      justify-self: center;
      
    }
  }
  @media only screen and (min-width: 2080px) {
    .slider-wrapper {
      right: 26%;
      justify-self: center;
      
    }
    .header_image{
      width: calc(100% - 556px);
      max-width: 1600px;
    }
    /* .randomImage{
      width: 2600px;
    } */
    .tree_links{
      /* left: 40%; */
      right: 400px;
    }
    .header_image .sukis{
      left: 55%;
    }
  }